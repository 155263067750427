/*screens*/
.large-desk,
.desk,
.desk-tab {
  display: block;
}

span.large-desk,
span.desk,
span.desk-tab,
a.large-desk,
a.desk,
a.desk-tab {
  display: inline-block;
}

.tablet-mob,
.mob {
  display: none;
}

/*-------------Responsive------------------*/

@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1200px) {
  /*screens*/
  .large-desk,
  span.large-desk,
  a.large-desk {
    display: none;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  /*screens*/
  .desk,
  span.desk,
  a.desk {
    display: none;
  }
  .tablet-mob {
    display: block;
  }
  span.tablet-mob,
  a.tablet-mob {
    display: inline-block;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  /*screens*/
  .desk-tab,
  span.desk-tab,
  a.desktab {
    display: none;
  }
  .mob {
    display: block;
  }
  span.mob,
  a.mob {
    display: inline-block;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 550px) {
}
