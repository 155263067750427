/* scrollRIG */
/*scrollRig option*/
[data-scroll="scrollRig"] html,
[data-scroll="scrollRig"] body {
  overflow: hidden;
}
/*scrollRig option*/
.scroll-rig {
  height: 100vh;
}

.scroll-rig.disabled {
  height: auto;
}

/*  */
.scrollbar-track {
  width: 7px !important;
}
.scrollbar-thumb {
  width: 7px !important;
  border-radius: 0px !important;
}
