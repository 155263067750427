/* views */
.content-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 100%;
}

.title-xl {
  font-family: var(--tertiary-font);
  font-weight: 900;
  font-size: 8rem;
  text-transform: uppercase;
}

.title-xl.outline {
  color: rgba(255, 255, 255, 0.4);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: grey;
}

[data-theme="dark"] .title-xl.outline {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  color: rgba(255, 255, 255, 0.1);
}

.title-md {
  font-family: var(--tertiary-font);
  font-weight: 900;
  font-size: 3rem;
  color: #888888;
}

.title-md.white {
  color: var(--primary-font-color);
}

.title-sm {
  font-family: var(--tertiary-font);
  font-weight: 600;
  font-size: 1.8rem;
}

.subtitle {
  font-weight: 600;
  font-size: 1.4rem;
  color: var(--tertiary-font-color);
}

.subtitle.white {
  color: var(--primary-font-color);
}

/* sextions variant bg */
.variant-bg {
  background-color: var(--variant-bg-color);
}

/* showreel button */
.showreel-btn {
  z-index: 2;
  text-align: center;
  cursor: pointer;
}
.showreel-btn h6 {
  color: white;
}
.showreel-btn .play-icon {
  width: 65px;
  height: 65px;
  color: white;

  /**/
  -webkit-transition: color 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition: color 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -ms-transition: color 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition: color 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  transition: color 0.4s cubic-bezier(0.75, 0, 0.125, 1);
}

.showreel-btn:hover .play-icon {
  color: var(--secondary-color);
}

.modal-overlay {
  width: 100%;
  height: 100%;
  max-width: 940px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /*  background-color: red; */
}
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-wrapper::after {
  position: absolute;
  top: -50px;
  right: 0;
  content: "x";
  width: 20px;
  height: 20px;
  color: white;
  text-align: center;
  pointer-events: none;
  font-size: 2rem;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* icon button */
.icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  border-radius: 10px;
}

[data-theme="dark"] .icon-button {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.icon-button .icon {
  width: 40px;
  height: 40px;
  color: grey;

  /**/
  -webkit-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -ms-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
}
.icon-button span {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 10px;
  color: var(--tertiary-font-color);
}

.icon-button:hover .icon {
  color: var(--secondary-color);
}

/*  */
.cta-external {
  color: var(--secondary-color);
  font-weight: bold;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
}
.cta-external span {
  display: inline-block;
  padding-right: 5px;
}
.cta-external:hover {
  color: var(--octonary-color);
}

/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
  .title-xl {
    font-size: 7.5rem;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .title-xl {
    font-size: 4rem;
  }
  .subtitle {
    font-size: 1.2rem;
  }
  .title-md {
    font-size: 2.2rem;
  }
  .title-sm {
    font-size: 1.4rem;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .showreel-btn .play-icon {
    width: 80px;
    height: 80px;
  }

  .icon-button span {
    display: none;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
  .title-xl {
    font-size: 2rem;
  }
  .title-sm {
    font-size: 1.2rem;
  }
  .subtitle {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 320px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}
