/*link decoration*/
a {
  text-decoration: none;
}

/*custom scrollbar*/
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  background-color: #000000;
}
::-webkit-scrollbar-thumb {
  background-color: #888888;
  outline: #708090 solid 1px;
}

/* selections */
::-moz-selection {
  background: #f9dbb8;
  color: black;
}
::selection {
  background: #f9dbb8;
  color: black;
}

/* focus */
*:focus {
  outline: none;
}

/*img-responsive*/
.img-responsive {
  width: 100%;
  height: auto;
  display: block;
}

/*image backgrounds*/
.img-background {
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.img-background-cover {
  /**/
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.img-background-auto {
  /**/
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: auto;
  -moz-background-size: auto;
  -o-background-size: auto;
  background-size: auto;
}

/*spacers*/
.v-spacer-10,
.v-spacer-20,
.v-spacer-30,
.v-spacer-40,
.v-spacer-50,
.v-spacer-60,
.v-spacer-70,
.v-spacer-80,
.v-spacer-90,
.v-spacer-100 {
  overflow: hidden;
  width: 100%;
  clear: both;
}
.v-spacer-10 {
  height: 10px;
  overflow: hidden;
}
.v-spacer-20 {
  height: 20px;
  overflow: hidden;
}
.v-spacer-30 {
  height: 30px;
  overflow: hidden;
}
.v-spacer-40 {
  height: 40px;
  overflow: hidden;
}
.v-spacer-50 {
  height: 50px;
  overflow: hidden;
}
.v-spacer-60 {
  height: 60px;
  overflow: hidden;
}
.v-spacer-70 {
  height: 70px;
  overflow: hidden;
}
.v-spacer-80 {
  height: 80px;
  overflow: hidden;
}
.v-spacer-90 {
  height: 90px;
  overflow: hidden;
}
.v-spacer-100 {
  height: 100px;
  overflow: hidden;
}

/*GPU acceleration*/
.gpu-acceleration {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* Other transform properties goes below */
}

/*no select*/
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
a {
  outline: none;
}

.sharpen {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

/*hidden*/
.hidden {
  opacity: 0;
  transform: translateY(80px);
}

/*-------------Responsive------------------*/

@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 550px) {
}
