:root {
  /* colors */
  --primary-color: #285c9b;
  --secondary-color: #f2862e;
  --tertiary-color: #ecd7cb;
  /* bg variants */
  --quaternary-color: #e1e1e1;
  --quinary-color: #5c5b5c;
  --senary-color: #1a1a1a;
  --septenary-color: #666666;
  --octonary-color: #ab2f34;
  --variant-bg-color: #f1f1f1;
  /* nonary, and denary */

  /* header footer bg */
  --header-bg-color: transparent;
  --nav-toggle-color: var(--senary-color);
  --navigation-bg-color: #f8f8f8;
  --main-bg-color: #f9f9f9;
  --footer-bg-color: var(--quaternary-color);

  /* carousel */
  --carousel-infobox-bg: var(--tertiary-color);
  --carousel-nav-btns-bg: var(--quaternary-color);
  --home-aside-bg: var(--quaternary-color);

  /* fonts */
  --primary-font: "Open Sans", sans-serif;
  --secondary-font: "Lato", sans-serif;
  --tertiary-font: "Montserrat", sans-serif;
  --quaternary-font: "Roboto", sans-serif;

  --font-size: 16px;

  /* fonts colors */
  --primary-font-color: var(--senary-color);
  --secondary-font-color: #c7c7c7;
  --tertiary-font-color: #949494;

  /* icons */
  --icons-lg: 2rem;
  --icons-md: 1.5rem;
  --icons-sm: 1rem;

  /* spacing */
  --spacing-unit-xsm: 5px;
  --spacing-unit-sm: 20px;
  --spacing-unit-md: 40px;
  --spacing-unit-lg: 60px;
  --spacing-unit-xlg: 120px;
  --spacing-unit-xxlg: 200px;

  /* Horizontal */
  --h-margin-ltr-xsm: 0 0 0 var(--spacing-unit-xsm);
  --h-margin-ltr-sm: 0 0 0 var(--spacing-unit-sm);
  --h-margin-ltr-md: 0 0 0 var(--spacing-unit-md);
  --h-margin-ltr-lg: 0 0 0 var(--spacing-unit-lg);

  --h-margin-rtl-xsm: 0 var(--spacing-unit-xsm) 0 0;
  --h-margin-rtl-sm: 0 var(--spacing-unit-sm) 0 0;
  --h-margin-rtl-md: 0 var(--spacing-unit-md) 0 0;
  --h-margin-rtl-lg: 0 var(--spacing-unit-lg) 0 0;

  --h-padding-ltr-sm: 0 0 0var (--spacing-unit-sm);
  --h-padding-ltr-md: 0 0 0var (--spacing-unit-md);
  --h-padding-ltr-lg: 0 0 0var (--spacing-unit-lg);

  --h-padding-rtl-sm: 0 var(--spacing-unit-sm) 0 0;
  --h-padding-rtl-md: 0 var(--spacing-unit-md) 0 0;
  --h-padding-rtl-lg: 0 var(--spacing-unit-lg) 0 0;

  /* Vertical */
  --v-margin-t-xsm: var(--spacing-unit-xsm) 0 0 0;
  --v-margin-t-sm: var(--spacing-unit-sm) 0 0 0;
  --v-margin-t-md: var(--spacing-unit-md) 0 0 0;
  --v-margin-t-lg: var(--spacing-unit-lg) 0 0 0;
  --v-margin-t-xlg: var(--spacing-unit-xlg) 0 0 0;
  --v-margin-t-xxlg: var(--spacing-unit-xxlg) 0 0 0;

  --v-margin-b-sm: 0 0 var(--spacing-unit-sm) 0;
  --v-margin-b-md: 0 0 var(--spacing-unit-md) 0;
  --v-margin-b-lg: 0 0 var(--spacing-unit-lg) 0;
  --v-margin-b-xlg: 0 0 var(--spacing-unit-xlg) 0;
  --v-margin-b-xxlg: 0 0 var(--spacing-unit-xxlg) 0;

  --v-margin-tb-sm: var(--spacing-unit-sm) 0 var(--spacing-unit-sm) 0;
  --v-margin-tb-md: var(--spacing-unit-md) 0 var(--spacing-unit-md) 0;
  --v-margin-tb-lg: var(--spacing-unit-lg) 0 var(--spacing-unit-lg) 0;
  --v-margin-tb-xlg: var(--spacing-unit-xlg) 0 var(--spacing-unit-xlg) 0;
  --v-margin-tb-xxlg: var(--spacing-unit-xxlg) 0 var(--spacing-unit-xxlg) 0;

  --v-padding-t-sm: var(--spacing-unit-sm) 0 0 0;
  --v-padding-t-md: var(--spacing-unit-md) 0 0 0;
  --v-padding-t-lg: var(--spacing-unit-lg) 0 0 0;
  --v-padding-t-xlg: var(--spacing-unit-xlg) 0 0 0;

  --v-padding-b-sm: 0 0 var(--spacing-unit-sm) 0;
  --v-padding-b-md: 0 0 var(--spacing-unit-md) 0;
  --v-padding-b-lg: 0 0 var(--spacing-unit-lg) 0;
  --v-padding-b-xlg: 0 0 var(--spacing-unit-xlg) 0;

  --v-padding-tb-sm: var(--spacing-unit-sm) 0 var(--spacing-unit-sm) 0;
  --v-padding-tb-md: var(--spacing-unit-md) 0 var(--spacing-unit-md) 0;
  --v-padding-tb-lg: var(--spacing-unit-lg) 0 var(--spacing-unit-lg) 0;
  --v-padding-tb-xlg: var(--spacing-unit-xlg) 0 var(--spacing-unit-xlg) 0;
}

[data-theme="dark"] {
  /* fonts colors */
  --primary-font-color: whitesmoke;
  --secondary-font-color: #c7c7c7;
  --tertiary-font-color: #949494;

  /* header footer bg */
  --header-bg-color: transparent;
  --nav-toggle-color: white;
  --navigation-bg-color: var(--senary-color);
  --main-bg-color: #222222;
  --footer-bg-color: #090909;

  /*  */
  --variant-bg-color: var(--senary-color);

  /* carousel */
  --carousel-infobox-bg: var(--primary-color);
  --carousel-nav-btns-bg: var(--senary-color);
  --home-aside-bg: var(--senary-color);
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  :root {
    --font-size: 14px;
  }
}
