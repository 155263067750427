/*form fields*/
.flex-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.full-feild {
  flex-grow: 1;
  flex-basis: 100%;
}
.half-feild {
  flex-basis: 50%;
  max-width: calc(50% - 10px);
}
.tierce-feild {
  flex-basis: 33.33333333333333%;
}

/* form controls */
.flex-form .form-controls.align-right {
  margin-left: auto;
}
.flex-form .form-controls.align-left {
  margin-right: auto;
}
.flex-form .form-controls.align-left .form-button {
  margin-right: 10px;
}
.flex-form .form-controls.align-right .form-button {
  margin-left: 10px;
}
.form-alerts {
  width: 100%;
}
/* mui override darkmode */
[data-theme="dark"] .MuiInputBase-root {
  color: rgba(255, 255, 255, 1);
}
[data-theme="dark"] .MuiFormLabel-root {
  color: rgba(255, 255, 255, 1);
}
[data-theme="dark"] .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.2);
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  /*form fields*/
  .flex-form {
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .full-feild {
    width: 100%;
    flex-basis: 100%;
  }
  .half-feild {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .tierce-feild {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex-form .form-controls.align-right {
    margin-left: initial;
  }
  .flex-form .form-controls.align-left {
    margin-right: initial;
  }
  .flex-form .form-controls.align-left .form-button {
    margin-left: 5px;
    margin-right: 5px;
  }
  .flex-form .form-controls.align-right .form-button {
    margin-left: 5px;
    margin-right: 5px;
  }
}
